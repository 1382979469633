<template>
     <v-card :loading="loading">
               <v-tabs
                    v-model="selected"
                    color="primary"
                    light
                    fixed-tabs
                    slider-color="primary"
               >
                    <v-tab v-for="(news, country) in newsList" :key="country">
                         {{country}}
                    </v-tab>
               </v-tabs>
          <v-card-text>
               <v-tabs-items v-model="selected">
                    <v-tab-item
                    v-for="(news, country) in newsList"
                    :key="country+'news-tab'"
                    >
                    
                         list of news in homepage for {{country}}

                         <v-divider></v-divider>

                         <v-list-item-group color="primary" v-if="news.length >= 1">
                              <v-list-item
                                   v-for="item in news"
                                   :key="item.id"
                                   target="_blank"
                                   :href="item.link"
                              >    
                                   <v-list-item-content>
                                        <v-img width="100%" max-height="420px" :src="item.image.url"></v-img>
                                       <v-list-item-title v-html="item.title"></v-list-item-title>
                                        <v-list-item-subtitle v-html="item.link"></v-list-item-subtitle>
                                   </v-list-item-content>
                              </v-list-item>
                         </v-list-item-group>
                         <div v-else>
                              NO NEWS IN homepage Tag for this country {{country}}
                         </div>

                    </v-tab-item>
               </v-tabs-items>
          </v-card-text>
     </v-card>
</template>

<script>
import {mapGetters} from "vuex"
export default {
     computed: {
          ...mapGetters({
              isLoading: 'news/loadingState',
              newsList: 'news/getNewsList',
          }),
          loading: {
              get() {
                  return this.isLoading
              },
              set(v) {
                  this.$store.dispatch('news/setLoadingStatus', v)
              }
          },
     },
     data: () => ({
          selected: null,
     }),
     mounted(){
          this.getNews()
     },
     methods:{
          async getNews(){
               this.$store.dispatch('news/fetchNews')
          }
     }
}
</script>