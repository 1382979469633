<template>
    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
        fullscreen
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
        >
          {{$t('commons.actions.new')}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-container>

            <v-row>
              <v-col cols="4">
                <v-text-field
                    v-model="page.name"
                    :rules="requiredRules"
                    :label="$t('pages.fields.name')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                    v-model="page.url_name"
                    :rules="requiredRules"
                    :label="$t('pages.fields.url_name')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="4">
                <countries
                    :country.sync="page.country"
                    :required-rules="requiredRules"
                ></countries>
              </v-col>

              <v-col cols="4">
                <languages
                    :language.sync="page.language"
                    :required-rules="requiredRules"
                ></languages>
              </v-col>

              <v-col cols="4">
                <v-text-field
                    v-model="page.category"
                    :rules="requiredRules"
                    :label="$t('pages.fields.category')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8">
                <v-text-field
                    v-model="page.title"
                    :rules="requiredRules"
                    :label="$t('pages.fields.title')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-switch label="view HTML" v-model="viewHtml"></v-switch>
                <tiptap-vuetify 
                      v-if="!viewHtml"
                      v-model="page.text"
                      :extensions="extensions"
                      max-height="300"
                      placeholder="contenuto pagina"
                ></tiptap-vuetify>
               <v-textarea
                  v-if="viewHtml"
                  v-model="page.text"
                  label="label"
                  name="name"
                  textarea
                ></v-textarea>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="storePage"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>

          </v-container>
        </v-form>

        <json-debug :objs="[{page: page}]" />

      </v-card>

    </v-dialog>
</template>

<script>

  import clonedeep from 'lodash.clonedeep'
  import DialogButtons from "@/components/form/DialogButtons";
  import Countries from "@/components/form/Countries";
  import Languages from "@/components/form/Languages";
  import {mapGetters} from "vuex";
  import {EventBus} from "@/event-bus";
  import { validationRules } from "@/mixins/validationRules";
  import { TiptapVuetify, Heading, Bold, Italic, Underline, Paragraph, OrderedList, ListItem, Link, HorizontalRule } from 'tiptap-vuetify'

  export default {
    name: "Form",

    components: {DialogButtons, Countries, Languages, TiptapVuetify},

    mixins: [validationRules],

    data: () => ({
      page: {},
      viewHtml: false,
      defaultPage: {
        url_name: "",
        name: "",
        country: "",
        language: "",
        category: "",
        title: "",
        document: "",
        text: "",
      },
      isEditing: false,
      dialog: false,
      sending: false,
      valid: false,
      extensions: [
        Link,
        Underline,
        Italic,
        ListItem,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        HorizontalRule,
        Paragraph
      ],
    }),

    mounted() {

      let self = this

      this.page = clonedeep(this.defaultPage)

      EventBus.$on('edit-page', function (page) {
        self.$store.dispatch('page/fetchPage', page.id)
            .then(() => {
              self.page = self.$store.getters['page/getPage']
            })
        self.isEditing = true
        self.dialog = true
      })

      EventBus.$on('upload-complete', function (event) {
        console.log('event upload-complete ' , event)
        EventBus.$emit('close-image-dialog')
        self.page.image = event.res
      })

    },

    computed: {
      ...mapGetters({
        isLoading: 'page/loadingState'
      }),
      formTitle () {
        return (this.isEditing === true) ? this.$t('pages.edit.title') : this.$t('pages.create.title')
      },
    },

    methods: {
      openImageDialog() {
        EventBus.$emit('open-image-dialog', 'page', 'page')
      },

      async storePage() {
        const valid = await this.$refs.form.validate()

        if (valid) {
          if (!this.isEditing) {
            await this.$store.dispatch('page/store', this.page)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          } else {
            await this.$store.dispatch('page/update', {
              id: this.page.id,
              page: this.page
            })
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          }
        }
      },

      close () {
        this.dialog = false
        this.valid = true
        this.$refs.form.reset()
        this.$nextTick(() => {
          this.isEditing = false
          this.page = clonedeep(this.defaultPage)
        })
      },
    }
  }
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>