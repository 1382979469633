<template>
     <v-sheet class="pa-5">
          <v-col>
               <v-card>
                    <v-card-title primary-title>
                         NEWS
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                         News su: https://news.venchi-cioccolatoegelato.it/
                         <br>
                          verranno visualizzate le news con tag: "homepage"
                         <br>
                         <br>
                         <v-btn target="_blank" href="https://news.venchi-cioccolatoegelato.it/wp-admin/edit.php" color="primary">Open External Blog</v-btn>
                         
                    </v-card-text>
               </v-card>
          </v-col>
          <v-col>
               <NewsList/>
          </v-col>
     </v-sheet>
</template>

<script>
import NewsList from '@/components/news/NewsList'
export default {
     name: "news_list",
     components:{
          NewsList
     }

}
</script>