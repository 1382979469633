<template>
    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
        fullscreen
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
        >
          {{$t('commons.actions.new')}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-container>

            <v-row>
              <v-col cols="4">
                <countries
                    :country.sync="discountcode.country"
                    :required-rules="requiredRules"
                ></countries>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  :label=" $t('discountcode.fields.discount_code')"
                  v-model="discountcode.discount_code"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label=" $t('discountcode.fields.description')"
                  v-model="discountcode.description"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="tipes"
                  :menu-props="{ top: true, offsetY: true }"
                  :label=" $t('discountcode.fields.type')"
                  v-model="discountcode.type"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <datepicker
                  :date.sync="discountcode.start"
                  :required="true"
                  :max="discountcode.end"
                  :label="$t('discountcode.fields.start')"
                ></datepicker>
              </v-col>
              <v-col cols="6">
                <datepicker
                  :date.sync="discountcode.end"
                  :required="true"
                  :label="$t('discountcode.fields.end')"
                  :min="discountcode.start"
                ></datepicker>
              </v-col>
              <v-col cols="12">
                 <v-text-field
                  :label=" $t('discountcode.fields.quantity')"
                  v-model="discountcode.quantity"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="discountcode.type != 'delivery'">

                <v-slider
                  v-model="discountcode.discount_amount"
                  :label="$t('discountcode.fields.discount_amount')"
                  thumb-color="primary"
                  thumb-label="always"
                ></v-slider>

              </v-col>
              <v-col cols="12" v-if="discountcode.type == 'product'">
                 <v-text-field
                  :label=" $t('discountcode.fields.product_sku')"
                  v-model="discountcode.product_sku"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="storeDiscountcode"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>

          </v-container>
        </v-form>

        <json-debug :objs="[{discountcode: discountcode}]" />

      </v-card>

    </v-dialog>
</template>

<script>

  import clonedeep from 'lodash.clonedeep'
  import moment from 'moment'
  import DialogButtons from "@/components/form/DialogButtons";
  import Datepicker from "@/components/form/Datepicker";
  import Countries from "@/components/form/Countries";
  import Languages from "@/components/form/Languages";
  import {mapGetters} from "vuex";
  import {EventBus} from "@/event-bus";
  import { validationRules } from "@/mixins/validationRules";
  export default {
    name: "Form",

    components: {DialogButtons, Countries, Languages,Datepicker},

    mixins: [validationRules],

    data: () => ({
      discountcode: {},
      defaultDiscountcode: {
        discount_code: 0,
        description: "",
        type: "",
        quantity: 20000,
        start: null,
        end: null,
        dates: [
          moment().toISOString().substr(0, 10), 
          moment().add(1,'days').toISOString().substr(0, 10),
        ],
        country: null,
      },
      tipes: ['cart','product','delivery'],
      isEditing: false,
      dialog: false,
      sending: false,
      valid: false,
    }),

    mounted() {

      let self = this

      this.discountcode = clonedeep(this.defaultDiscountcode)

      EventBus.$on('edit-discountCode', function (discountcode) {
       self.discountcode = discountcode
        self.isEditing = true
        self.dialog = true
        discountcode.start = discountcode.start.substr(0, 10)
        discountcode.end = discountcode.end.substr(0, 10)
        discountcode.discount_amount = discountcode.discount_amount * 100
      })

    },

    computed: {
      ...mapGetters({
        isLoading: 'discountCode/loadingState'
      }),
      formTitle () {
        return (this.isEditing === true) ? this.$t('discountcode.edit.title') : this.$t('discountcode.create.title')
      },
    },

    methods: {
      
      async storeDiscountcode() {
        const valid = await this.$refs.form.validate()

        if (valid) {

           if(this.discountcode.type != "product"){
              delete this.discountcode.product_sku
            }

            if(this.discountcode.type == "delivery"){
             delete this.discountcode.discount_amount;
            }

            if(this.discountcode.discount_amount >= 0){
             this.discountcode.discount_amount = this.discountcode.discount_amount / 100
            }

          if (!this.isEditing) {
            
            await this.$store.dispatch('discountCode/store', this.discountcode)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          } else {
            await this.$store.dispatch('discountCode/update', {
              id: this.discountcode.id,
              discountcode: this.discountcode
            })
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          }
        }
      },

      close () {
        this.dialog = false
        this.valid = true
       // this.$refs.form.reset()
        this.$nextTick(() => {
          this.isEditing = false
          this.discountcode = clonedeep(this.defaultDiscountcode)
        })
      },
    }
  }
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>