<template>
    <div>
       <v-data-table
            :loading="loading"
            :loading-text="$t('commons.loading')"
            :headers="headers"
            :items="pages"
            :items-per-page="pagination.per_page"
            :hide-default-footer="true"
            class="elevation-0 clickable"
            disable-pagination
       >
         <template v-slot:item.actions="{ item }">
           <v-icon
               small
               class="mr-2"
               @click="editPage(item)"
           >
             mdi-pencil
           </v-icon>

           <v-icon
               small
               class="mr-2"
               @click="viewPage(item)"
           >
             mdi-eye
           </v-icon>
         </template>
        </v-data-table>

        <v-divider></v-divider>

        <div class="text-center py-3">
            <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                :total-visible="5"
                @input="getPages()"
                :disabled="isLoading"
            ></v-pagination>
        </div>

      <json-debug :objs="[{pages: pages}]"></json-debug>
    </div>
</template>

<script>
    import {mapGetters} from "vuex"
    import {EventBus} from "@/event-bus"

    export default {
        name: "List",
        data() {
            return {
                pageFilter: '',
                filterMenu: false,
                filters: {
                    exact: {},
                    like: {}
                },
                headers: [
                  { text: this.$t('commons.fields.id'), value: 'id', sortable: false, width: '5%'},
                  { text: this.$t('pages.fields.url_name'), value: 'url_name', sortable: false, width: '15%', align: 'center' },
                  { text: this.$t('pages.fields.title'), value: 'title', sortable: false },
                  { text: this.$t('pages.fields.category'), value: 'category', sortable: false, width: '15%' , align: 'center' },
                  { text: this.$t('pages.fields.country'), value: 'country', sortable: false, width: '5%' , align: 'center' },
                  { text: this.$t('pages.fields.language'), value: 'language', sortable: false, width: '5%' , align: 'center' },
                  {text: '', value: 'actions', sortable: false, width: '10%'},
                ]
            }
        },

        mounted() {
            this.getPages()
        },

        methods: {
            getPages() {
                this.$store.dispatch('page/fetchPages', this.filters)
            },

          editPage (page) {
            console.log('edit page ' , page)
            this.isEditing = true
            EventBus.$emit('edit-page', page)
            this.dialog = true
          },
        },

        computed: {
          ...mapGetters({
              isLoading: 'page/loadingState',
              pages: 'page/getPages',
              pagination: 'page/getPagination'
          }),

          loading: {
              get() {
                  return this.isLoading
              },
              set(v) {
                  this.$store.dispatch('page/setLoadingStatus', v)
              }
          },
        }
    }
</script>

<style scoped>

</style>
