<template>
    <v-sheet class="pa-5">
      <page-form></page-form>
      <pages-list></pages-list>
    </v-sheet>

</template>

<script>
    import List from "@/components/pages/List";
    import Form from "@/components/pages/Form";

    export default {

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
            'pages-list': List,
            'page-form': Form,
        }
    }
</script>

<style lang="scss" scoped>
</style>
