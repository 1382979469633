<template>

  <v-select
      v-model="localModel"
      :label="$t('commons.fields.language')"
      :items="languages"
      :rules="requiredRules"
      :required="required"
      outlined
      dense
  ></v-select>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "Languages",

    props: {
      language: String,
      required: {
        type: Boolean,
        default: false
      },
      requiredRules: Array
    },

    computed: {
      ...mapGetters({
        languages: 'consts/getLanguages'
      }),

      localModel: {
        get() {
          return this.language
        },
        set(language) {
          this.$emit('update:language', language)
        }
      }
    },
  }
</script>

<style scoped>

</style>