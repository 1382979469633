<template>
    <div>
       <v-data-table
            :loading="loading"
            :loading-text="$t('commons.loading')"
            :headers="headers"
            :items="discountcodes"
            :items-per-page="pagination.per_page"
            :hide-default-footer="true"
            class="elevation-0 clickable"
            disable-pagination
       >
         <template v-slot:item.discount_amount="{ item }">
           {{item.discount_amount * 100 }}%
         </template>
         <template v-slot:item.actions="{ item }">
           <v-icon
               small
               class="mr-2"
               @click="editDiscountCode(item)"
           >mdi-pencil
           </v-icon>
         </template>
         
        </v-data-table>

        <v-divider></v-divider>

        <div class="text-center py-3">
            <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                :total-visible="5"
                @input="getDiscountCodes()"
                :disabled="isLoading"
            ></v-pagination>
        </div>

      <json-debug :objs="[{discountcodes: discountcodes}]"></json-debug>
    </div>
</template>

<script>
    import {mapGetters} from "vuex"
    import {EventBus} from "@/event-bus"

    export default {
        name: "List",
        data() {
            return {
                pageFilter: '',
                filterMenu: false,
                filters: {
                    exact: {},
                    like: {}
                },
                headers: [
                  { text: this.$t('commons.fields.id'), value: 'id', sortable: false, width: '5%'},
                  { text: this.$t('discountcode.fields.country'), value: 'country', sortable: false, width: '5%' , align: 'center' },
                  { text: this.$t('discountcode.fields.discount_code'), value: 'discount_code', sortable: false, width: '5%' , align: 'center' },
                  { text: this.$t('discountcode.fields.type'), value: 'type', sortable: false, width: '5%' , align: 'center' },
                  { text: this.$t('discountcode.fields.is_active'), value: 'is_active', sortable: false, width: '5%' , align: 'center' },
                  { text: this.$t('discountcode.fields.discount_amount'), value: 'discount_amount', sortable: false, width: '5%' , align: 'center' },
                  { text: this.$t('discountcode.fields.quantity'), value: "quantity", sortable: false, width: '5%' , align: 'center' },
                  { text: this.$t('discountcode.fields.used'), value: "used", sortable: false, width: '5%' , align: 'center' },
                  { text: '', value: 'actions', sortable: false, width: '5%'},
                ]
            }
        },

        mounted() {
            this.getDiscountCodes()
        },

        methods: {
            getDiscountCodes() {
                this.$store.dispatch('discountCode/fetchDiscountCodes', this.filters)
            },

          editDiscountCode (item) {
            console.log('edit discountCode ' , item)
            this.isEditing = true
            EventBus.$emit('edit-discountCode', item)
            this.dialog = true
          },
        },

        computed: {
          ...mapGetters({
              isLoading: 'discountCode/loadingState',
              discountcodes: 'discountCode/getDiscountCodes',
              pagination: 'discountCode/getPagination'
          }),

          loading: {
              get() {
                  return this.isLoading
              },
              set(v) {
                  this.$store.dispatch('discountCode/setLoadingStatus', v)
              }
          },
        }
    }
</script>

<style scoped>

</style>
